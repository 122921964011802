<template>
  <div>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>

      <b-col md="10" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="8"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="itemData"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(type)="data">
            <b-badge v-if="data.item.type === 1" variant="primary">
              Finished Product
            </b-badge>
            <b-badge v-if="data.item.type === 2" variant="info">
              Semi Finished Product
            </b-badge>
            <b-badge v-if="data.item.type === 3" variant="secondary">
              Raw Material
            </b-badge>
          </template>
          <template #cell(CreatePo)="data">
            <b-button variant="primary" :to="createPurchase(data.item.itemId)">
              Create Purchase Order
            </b-button>
          </template>
        </b-table>
        <div class="d-flex justify-content-center mb-1">
          <b-spinner
            variant="success"
            label="Spinning"
            block
            v-if="loading"
            style="width: 3rem; height: 3rem"
          ></b-spinner>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BSpinner,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      itemData: [],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "itemName", label: "Item Name" },
        { key: "type", label: "Item Type" },
        { key: "currentStock", label: "Current Stock" },
        { key: "CreatePo", label: "Create Purchase Order" },
      ],
    };
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("notificationModule", {
      notification: "notification",
      loading: "loading",
    }),
  },

  methods: {
    ...mapActions("notificationModule", ["getNotificationsAction"]),
    getUomName(id) {
      let uom = this.itemuom.find((uom) => uom.id == id);
      return uom.name;
    },
    getUpdate(item) {
      return "/rawmaterials/stockTransaction/" + item;
    },
    createPurchase(item) {
      return "/purchaseorders/add/" + item + "/" + 1;
    },
    getRawMaterialEdit(item) {
      return "/rawmaterials/edit/" + item;
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ...mapActions("rawmaterialModule", [
      "getRawMaterialListAction",
      "removeRawMaterialAction",
    ]),
  },
  async mounted() {
    await this.getNotificationsAction().then(() => {
      this.itemData = this.notification.itemList;
      this.totalRows = this.notification.itemList.length;
    });
  },
};
</script>
